<template>
  <div>
    <header-title link="Dashboard">
      <h3 slot="title">
        이미지 관리
        <tooltip
          :text="`테마에 사용할 이미지를 관리할 수 있습니다.<br/>
          ･ 이미지에 사용되는 트래픽은 무제한 무료로 제공됩니다.<br/>
          ･ 이미지 삭제 시 테마에 적용된 이미지도 삭제 처리되오니 주의하시기 바랍니다.`"
        />
      </h3>
    </header-title>
    <div class="main">
      <div class="main__container">
        <div class="fileLayout">
          <FileUsage :fileUsage="fileUsage" />
          <FileUpload :fileUsage="fileUsage" @imagesAdd="imagesAdd" />
        </div>
        <div class="frmLayout">
          <div class="frmUtils">
            <label>
              <input type="checkbox" v-model="selectAll" />
              <span></span>
            </label>
            <button type="button" @click="imageDelete">
              <span>삭제</span>
            </button>
          </div>
          <div class="frmList">
            <div v-if="!fileList.length" class="data-empty">
              <img src="@/assets/images/admin/icon_empty.svg" />
              <p>데이터가 없습니다.</p>
            </div>
            <ul v-else>
              <li v-for="file in fileList" :key="file.id">
                <label>
                  <input type="checkbox" :value="file" v-model="selectList" />
                  <span></span>
                </label>
                <div class="frmList__thumb" @click="togglePopup(file.url)">
                  <img :src="file.url" />
                </div>
                <dl class="frmList__desc">
                  <dt>{{ file.orgName }}</dt>
                  <dd>{{ (file.size / 1024).toFixed(0) + "KB" }}</dd>
                </dl>
              </li>
            </ul>
          </div>
          <div class="pagination" v-show="isPagination">
            <button
              type="button"
              class="pagination--prev"
              v-show="pagination.prev"
              @click="goPage(pagination.prev.start)"
            ></button>
            <button
              type="button"
              v-for="page in pagination.pages"
              :key="page"
              :class="{'pagination--active': pagination.page === page}"
              @click="goPage(page)"
            >
              {{ page }}
            </button>
            <button
              type="button"
              class="pagination--next"
              v-show="pagination.next"
              @click="goPage(pagination.next.start)"
            ></button>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">ⓒ QPICK</div>
    <Dimmed v-show="isPopup" />
    <div v-show="isPopup" class="popup" @click="togglePopup">
      <div class="popup__thumb">
        <button type="button" class="popup__thumb--close">닫기</button>
        <img ref="thumb" />
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const ModuleAlert = createNamespacedHelpers("ModuleAlert");
const ModuleAttach = createNamespacedHelpers("ModuleAttach");
const ModuleConfirm = createNamespacedHelpers("ModuleConfirm");

const PerPage = 20;
const PerBlock = 5;

export default {
  data() {
    return {
      page: 1,
      selectCnt: 0,
      selectList: [],
      fileList: [],
      pagination: {},
      fileUsage: null,
      isPopup: false
    };
  },
  watch: {
    // 컴포넌트 이미지 목록
    getComponentImageListData(data) {
      this.fileList = data.list;
      this.pagination = data.header;

      this.pagination.pages = [];
      for (
        let i = this.pagination.startPage;
        i <= this.pagination.endPage;
        i++
      ) {
        this.pagination.pages.push(i);
      }
    },
    // 컴포넌트 이미지 사용량 조회
    getComponentImageUsageData(data) {
      this.fileUsage = data.storage.usage;
    }
  },
  computed: {
    // pagination 유무
    isPagination() {
      return this.pagination.totalCount > this.pagination.perPage;
    },
    // 이미지 전체 선택
    selectAll: {
      get() {
        return (
          this.selectList.length &&
          this.selectList.length === this.fileList.length
        );
      },
      set(e) {
        this.selectList = e ? this.fileList : [];
      }
    },

    ...ModuleAttach.mapGetters([
      "getComponentImageListData",
      "getComponentImageDeleteData",
      "getComponentImageUsageData"
    ])
  },
  methods: {
    // 이미지 팝업 toggle
    togglePopup(url) {
      this.isPopup = !this.isPopup;

      if (url) {
        this.$refs.thumb.setAttribute("src", url);
      }
    },
    // 페이지 이동
    goPage(page) {
      this.page = page;

      this.imageList();
      this.imageUsage();
      window.scrollTo(0, 0);
    },
    // 컴포넌트 이미지 목록
    imageList() {
      let payload = {};
      payload.sortBy = "-id";
      payload.page = this.page;
      payload.perPage = PerPage;
      payload.perBlock = PerBlock;

      this.actComponentImageList(payload).then(() => {});
    },
    // 컴포넌트 이미지 업로드
    async imagesAdd(files) {
      let filesCnt = files.length;

      for (let file of files) {
        let payload = {};
        payload.files = file;

        await this.actComponentImageAdd(payload).then(() => {
          filesCnt--;
          if (!filesCnt) {
            this.page = 1;
            this.imageList();
            this.imageUsage();
          }
        });
      }
    },
    // 컴포넌트 이미지 삭제
    imageDelete() {
      if (!this.selectList.length) {
        this.setAlert({
          isVisible: true,
          message: `삭제할 이미지를 선택하세요.`
        });
        return;
      }

      this.selectCnt = this.selectList.length;
      for (let image of this.selectList) {
        this.componentImageDelete(image.componentUploadImage.id);
      }
    },
    // 컴포넌트 이미지 삭제
    componentImageDelete(id) {
      let payload = {};
      payload.id = id;

      this.actComponentImageDelete(payload).then(() => {
        this.selectCnt--;

        if (!this.selectCnt) {
          this.page = 1;
          this.imageList();
          this.imageUsage();
          this.selectList = [];
          this.setAlert({
            isVisible: true,
            message: `선택한 이미지가 삭제되었습니다.`
          });
        }
      });
    },
    // 컴포넌트 이미지 사용량 조회
    imageUsage() {
      this.actComponentImageUsage().then(() => {});
    },

    ...ModuleAlert.mapMutations(["setAlert"]),
    ...ModuleConfirm.mapMutations(["setConfirm"]),
    ...ModuleAttach.mapMutations([
      "setComponentImageList",
      "setComponentImageAdd",
      "setComponentImageDelete",
      "setComponentImageUsage"
    ]),
    ...ModuleAttach.mapActions([
      "actComponentImageList",
      "actComponentImageAdd",
      "actComponentImageDelete",
      "actComponentImageUsage"
    ])
  },
  mounted() {
    this.imageList();
    this.imageUsage();
  },
  components: {
    HeaderTitle: () => import("@/components/admin/HeaderTitle"),
    Dimmed: () => import("@/components/basis/dimmed.vue"),
    FileUsage: () => import("@/components/modules/FileUsage.vue"),
    FileUpload: () => import("@/components/modules/FileUpload.vue")
  }
};
</script>

<style lang="scss">
.main {
  z-index: 0;
}
.main__container {
  padding-top: 24px;
}

.data-empty {
  background: #f7f9fc;
  border-radius: 8px;
  text-align: center;
  padding: 163px 0 445px 0;
  margin-top: 8px;

  @media screen and (max-width: 1023px) {
    padding: 163px 0 300px 0;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    color: #309574;
    margin-top: 27px;
  }
}
.file {
  &Layout {
    display: flex;
  }
  &Usage {
    width: 100%;
    height: 30px;
    overflow: hidden;

    &__visual {
      overflow: hidden;
      position: relative;
      height: 8px;
      background: #dcdcdc;
      border-radius: 8px;

      em {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background: #54c7a2;
        border-radius: 8px;
      }
    }
    p {
      margin-top: 6px;
      color: #484948;
      font-size: 12px;
    }
    &__remain {
      float: left;
    }
    &__text {
      float: right;

      span {
        color: #309574;
      }
    }
  }
}
.frm {
  &Utils {
    display: flex;
    padding-bottom: 8px;
    border-bottom: 1px solid #b7b7b7;

    label span:before {
      height: 32px;
    }
    button {
      display: block;
      width: 64px;
      height: 32px;
      margin-left: 12px;
      border: 1px solid #b7b7b7;
      border-radius: 4px;
      color: #8d8d8d;
      font-size: 12px;

      span {
        padding-left: 18px;
        background-repeat: no-repeat;
        background-position: 0 50%;
        background-image: url($path + "ico_trash_active.svg");
      }
    }
  }
  &List {
    position: relative;
    border-bottom: 1px solid #b7b7b7;

    li {
      display: flex;
      padding: 16px 0;
      border-bottom: 1px solid #f1f1f1;
    }
    &__thumb {
      flex-shrink: 0;
      width: 144px;
      height: 124px;
      margin-left: 12px;
      background-color: #dcdcdc;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &__desc {
      margin-left: 16px;

      dt {
        word-break: break-all;
      }

      dd {
        margin-top: 8px;
        color: #484948;
        font-size: 14px;
      }
    }
  }
}
.footer {
  margin: 40px 0;
  color: #8d8d8d;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
}
.pagination {
  display: flex;
  justify-content: center;
  gap: 8px;
  padding-top: 16px;

  button {
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    border: 1px solid #54c7a2;
    color: #54c7a2;
    font-size: 14px;
  }
  &--active {
    background-color: #54c7a2;
    color: #ffffff !important;
  }
  &--prev,
  &--next {
    border: 1px solid #8d8d8d !important;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  &--prev {
    margin-right: 16px;
    background-image: url($path + "ico_arr_left.svg");
  }
  &--next {
    margin-left: 16px;
    background-image: url($path + "ico_arr_right.svg");
  }
}
.errorLayout {
  position: relative;
  margin: 16px 0;
  border-radius: 8px;
}
.popup {
  z-index: 10000002;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadein 1s;

  &__thumb {
    position: relative;
    margin: 0 16px;

    img {
      width: 100%;
      max-width: 700px;
      max-height: 700px;
      object-fit: contain;
      vertical-align: bottom;

      @media screen and (max-width: 1023px) {
        max-width: 340px;
        max-height: 580px;
      }
    }
    &--close {
      position: absolute;
      top: -32px;
      right: 0;
      width: 24px;
      height: 24px;
      background-image: url($path + "ico_opt_close.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 14px;
      text-indent: -9999px;
    }
  }
}
/* 반응형 - desktop */
@media screen and (min-width: 1024px) {
  .main__container {
    padding-right: 24px;
    padding-left: 24px;
  }
  .frm {
    &Layout {
      margin-top: 40px;
    }
    &Utils {
      padding-right: 20px;
      padding-left: 20px;
    }
    &List {
      li {
        padding-right: 20px;
        padding-left: 20px;
      }
    }
  }
}
/* 반응형 - mobile */
@media screen and (max-width: 1023px) {
  .file {
    &Layout {
      flex-direction: column;
    }
  }
  .frm {
    &Layout {
      margin-top: 16px;
    }
  }
}
@media screen and (max-width: 339px) {
  .pagination {
    &--prev {
      margin-right: 0;
    }
    &--next {
      margin-left: 0;
    }
  }
}
</style>
